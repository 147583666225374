import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Looks like this page isn't quite ready yet...</h1>
      <p>Check back later for updates.</p>
    </div>
  );
}

export default App;
